<template>
  <v-card outlined>
    <v-data-table
      :headers="headers"
      :items="deals"
      :loading="loading"
      hide-default-header
      hide-default-footer
      class="main-table elevation-0"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top transition="fade-transition">
          <template v-slot:activator="{ on }">
            <v-btn icon small :to="goToDeal(item)" v-on="on">
              <v-icon small>{{ $vuetify.icons.values.view }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('labels.view') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { NAMESPACE } from '../store'

export default {
  name: 'ClientDeals',
  data: () => ({
    loading: true,
    headers: [
      { text: 'display', value: 'display', align: 'left' },
      { text: '', value: 'actions', sortable: false, align: 'right' }
    ]
  }),
  props: {
    clientId: { type: [Number, String], required: true }
  },
  computed: {
    ...mapState(NAMESPACE, ['deals'])
  },
  methods: {
    ...mapActions(NAMESPACE, ['getClientDeals', 'clearClientDeals']),
    goToDeal(deal) {
      return {
        path: `/deals/${deal.deal_type}/cards/${deal.id}`
      }
    }
  },
  created() {
    return this.getClientDeals(this.clientId).then(() => {
      this.loading = false
    })
  },
  destroyed() {
    this.clearClientDeals()
  }
}
</script>

<style></style>
