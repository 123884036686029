<template>
  <v-autocomplete
    v-model="localValue"
    :items="clients"
    :placeholder="$t('selectClients')"
    item-text="display"
    item-value="id"
    :return-object="returnObject"
    clearable
    :hide-no-data="!search || search.length < 3"
    :search-input.sync="search"
    :loading="loading"
    :menu-props="{ closeOnContentClick: true }"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          {{ item.display }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ item | clientInfo }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { LIKE_OPERATOR, AND_SEPARATOR } from '@argon/app/axios'
import { CustomFieldMixin } from '@argon/app/mixins'
import { NAMESPACE } from '../store'
export default {
  name: 'ClientField',
  mixins: [CustomFieldMixin],
  props: {
    returnObject: { type: Boolean, default: false },
    reset: { type: Boolean, default: false }
  },
  data: () => ({
    search: null,
    loading: false
  }),
  computed: {
    ...mapState(NAMESPACE, ['clients']),
    localClients() {
      return this.search && this.search.length > 2 ? this.clients : []
    }
  },
  filters: {
    clientInfo: (v) => {
      if (v.email) {
        return v.email
      }
      return v.contact.phones.length > 0 ? v.contact.phones[0].number : v.birthdate
    }
  },
  watch: {
    search: function (after, before) {
      if (after && after.length >= 3 && after.length > before.length) {
        this.doFilter(after)
      }
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['listClients', 'setFilters']),
    doFilter(v) {
      this.loading = true
      this.setFilters({ separator: AND_SEPARATOR, items: [{ field: 'display', operator: LIKE_OPERATOR, value: v }] })
      this.listClients({}).then(() => {
        this.loading = false
      })
    }
  }
}
</script>

<i18n>
{
  "en": {
    "selectClients": "Select Clients"
  },
  "fr": {
    "selectClients": "Choisir les clients"
  }
}
</i18n>

<style></style>
