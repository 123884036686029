<template>
  <v-card outlined class="comments">
    <v-data-table
      hide-default-header
      :items="sorted"
      class="elevation-0"
      :loading="loading"
      :page.sync="page"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      must-sort
      :items-per-page.sync="itemsPerPage"
      :footer-props="{ itemsPerPageOptions: itemsPerPageArray }"
      :server-items-length="notesCount"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td class="pa-2">
            <v-row no-gutters>
              <v-col cols="1">
                <v-avatar size="32px">
                  <img :src="item.owner.avatar" v-if="item.owner" />
                </v-avatar>
              </v-col>
              <v-col cols="6">
                <p class="subheading mb-1">
                  <strong v-if="item.owner">{{ item.owner.display }}</strong>
                  &nbsp;&nbsp;
                  <span class="text-caption">
                    {{ transformDate(item.created_on) }}
                  </span>
                </p>
              </v-col>
              <v-col cols="4">
                <span class="font-weight-bold">{{ item.entity.display }}</span>
                <span> • </span>
                <span class="lighten-3">{{ $t(`labels.${item.entity.deal_type}`) }}</span>
              </v-col>
              <v-col cols="1" class="text-right">
                <v-chip v-if="item.private" label small color="warning" text-color="warning darken-4">
                  {{ $t('private') }}
                </v-chip>
              </v-col>
              <v-col cols="11" offset="1" v-html="item.text" />
            </v-row>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { orderBy } from 'lodash/collection'
import { formatDT } from '@argon/app/helpers'
import { Datatable } from '@argon/app/mixins'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { NAMESPACE } from '../store'

export default {
  name: 'ClientNotes',
  mixins: [Datatable],
  data: () => ({ loading: true }),
  props: {
    clientId: { type: [Number, String], required: true }
  },
  computed: {
    ...mapGetters(ME_NAMESPACE, ['timezone', 'locale']),
    ...mapState(NAMESPACE, ['notes', 'notesCount']),
    sorted() {
      return orderBy(this.notes, ['created_on'], ['asc'])
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['getClientNotes', 'clearClientNotes']),
    transformDate(value) {
      return value ? formatDT(value, { format: 'LLL', tz: this.timezone, locale: this.locale }) : ''
    },
    doSearch(filters) {
      return new Promise((resolve, reject) => {
        this.getClientNotes({ filters, pager: this.pager, clientId: this.clientId })
          .then(resolve)
          .catch(reject)
          .finally(() => {
            this.loading = false
          })
      })
    },
    fetchData() {
      this.doSearch()
    }
  },
  created() {
    this.fetchData()
  },
  destroyed() {
    this.clearClientNotes()
  }
}
</script>

<style scoped lang="scss">
.avatar {
  vertical-align: top;
  width: 32px;
}
.v-data-table td.comment {
  padding: 5px;
}
</style>
