import { BasePerimeter } from '@argon/iam/perimeters'
import { ROLES } from '@argon/iam/utils'

export default new BasePerimeter({
  purpose: 'client',
  can: {
    update(client) {
      return this.hasAction(client, 'update')
    },
    destroy(client) {
      return this.hasAction(client, 'delete')
    },
    list() {
      return this.hasPermission('client:read')
    },
    create() {
      return this.hasPermission('client:create')
    },
    atLeastAdvisor() {
      let org = this.child.getters['organizations/activeOrg']
      return this.isLevelOrAbove(org, ROLES.MEMBER)
    },
    atLeastManager() {
      let org = this.child.getters['organizations/activeOrg']
      return this.isLevelOrAbove(org, ROLES.MANAGER)
    }
  }
})
