<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('title') }}</template>
    <template v-slot:header-actions>
      <v-btn icon small @click="onEdit">
        <v-icon small>{{ $vuetify.icons.values.edit }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:content>
      <v-list class="mb-1">
        <v-list-item class="py-1" v-for="(phone, idx) in client.contact.phones" :key="`phone-${idx}`">
          <v-list-item-content>
            <v-list-item-subtitle>
              <v-row>
                <v-col cols="8">{{ $t('labels.phone') }}</v-col>
                <v-col cols="4" class="text-right">
                  <v-chip label small>{{ $t(`labels.${phone.type}`) }}</v-chip>
                </v-col>
              </v-row>
            </v-list-item-subtitle>
            <v-list-item-title>{{ phone.number | formatPhone }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item class="py-1" v-for="(email, idx) in client.contact.emails" :key="`email-${idx}`">
          <v-list-item-content>
            <v-list-item-subtitle>
              <v-row>
                <v-col cols="8">{{ $t('labels.email') }}</v-col>
                <v-col cols="4" class="text-right">
                  <v-chip label small>{{ $t(`labels.${email.type}`) }}</v-chip>
                </v-col>
              </v-row>
            </v-list-item-subtitle>
            <v-list-item-title>{{ email.address }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item class="py-1" v-for="(address, idx) in client.contact.addresses" :key="idx">
          <v-list-item-content>
            <v-list-item-subtitle>
              <v-row>
                <v-col cols="8">{{ $t('labels.address') }}</v-col>
                <v-col cols="4" class="text-right">
                  <v-chip label small>
                    {{ $t(`labels.${address.type}`) }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-list-item-subtitle>
            <v-list-item-title>
              {{ address.street_address }}, {{ address.city }}, {{ address.province }}, {{ address.postal_code }}, ({{
                address.country_code
              }})
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item class="py-1">
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('birthdate') }}</v-list-item-subtitle>
            <v-list-item-title>{{ transformDate(client.birthdate) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item class="py-1">
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.language') }}</v-list-item-subtitle>
            <v-list-item-title>{{ $t(`labels.${client.language}`) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { formatDT } from '@argon/app/helpers'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { VWidget } from '@argon/app/components'

export default {
  components: { VWidget },
  props: {
    client: { type: Object, required: true },
    onEdit: { type: Function, required: true },
    editable: { type: Boolean, default: true }
  },
  computed: {
    ...mapGetters(ME_NAMESPACE, ['timezone', 'locale'])
  },
  methods: {
    transformDate(value) {
      return value ? formatDT(value, { format: 'LL', tz: 'UTC', locale: this.locale }) : ''
    }
  },
  filters: {
    formatPhone: (number) => {
      let parsed = parsePhoneNumberFromString(number)
      return parsed ? parsed.formatNational() : number
    }
  }
}
</script>

<style lang="scss" scoped>
.v-chip--small {
  height: 18px !important;
  font-size: 12px !important;
  margin: 0 !important;
}
</style>

<i18n>
{
  "en": {
    "birthdate": "Birthdate",
    "client": "@:labels.client",
    "title": "@:labels.info"
  },
  "fr": {
    "birthdate": "Anniversaire",
    "client": "@:labels.client",
    "title": "@:labels.info"
  }
}
</i18n>
