<template>
  <div>
    <v-autocomplete
      v-model="selected"
      :items="clients"
      small-chips
      deletable-chips
      :label="$t('labels.clients')"
      :hint="$t('selectClientsHint')"
      item-text="display"
      return-object
      :append-icon="$vuetify.icons.values.add"
      @click:append="create"
      hide-no-data
      hide-selected
      multiple
      :loading="loading"
      :disabled="readonly"
      :menu-props="{
        closeOnContentClick: true
      }"
    />
    <router-view></router-view>
  </div>
</template>

<script>
import { differenceBy } from 'lodash/array'
import { mapState, mapActions } from 'vuex'
import { NAMESPACE } from '../store'

export default {
  name: 'SelectClients',
  props: {
    clientLink: { type: String, default: 'nestedClient' },
    selection: { type: Array, default: () => [] },
    updateSelection: { type: Function, required: true },
    readonly: { type: Boolean, default: true }
  },
  watch: {
    clients: function (newItems, previousItems) {
      if (this.loading) {
        return
      }
      let added = differenceBy(newItems, previousItems, 'id')[0]
      if (added && !this.selection.includes(added.id)) {
        this.selected = [...this.selected, added]
      }
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapState(NAMESPACE, ['clients']),
    selected: {
      get() {
        return this.selection
      },
      set(value) {
        this.updateSelection(value)
      }
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['listClients']),
    create() {
      let params = { ...this.$route.params, clientId: 'new' }
      this.$router.push({ name: this.clientLink, params })
    }
  },
  created() {
    if (this.clients.length === 0) {
      this.loading = true
      this.listClients({})
        .then(() => {
          this.loading = false
        })
        .catch(() => {})
    }
  }
}
</script>

<i18n>
{
  "en": {
    "selectClients": "Select Clients",
    "selectClientsHint": "Select the clients you want to associate with this item"
  },
  "fr": {
    "selectClients": "Choisir les clients",
    "selectClientsHint": "Choisir les clients que vous souhaitez associer à cet élément"
  }
}
</i18n>

<style></style>
